<template>
  <Toast />
  <div>
    <file-pond
      class="mt-2"
      name="test"
      ref="pond"
      label-idle="Drop files here..."
      credits="false"
      maxFileSize="5MB"
      labelMaxFileSizeExceeded="El archivo es demasiado grande"
      labelMaxFileSize="El tamaño máximo del archivo es de 5mb"
      :allow-multiple="false"
      accepted-file-types="application/pdf"
      instantUpload="false"
      @init="handleFilePondInit"
      @addfile="handleFilePondAddFile"
      @initfile="handleFilePondInitFile"
      @removefile="handleFilePondRemoveFile"
      data-pdf-preview-height="640"
      data-pdf-component-extra-params="toolbar=0&navpanes=0&scrollbar=0&view=fitH"
      labelTapToCancel="Toca para cancelar"
      labelButtonAbortItemLoad = "Cancelar descarga"
      labelButtonRemoveItem = "Borrar archivo"
      labelButtonRetryItemLoad="Cargando"
      labelButtonRetryItemProcessing ="Reintentar"
      labelButtonUndoItemProcessing ="Deshacer"
      labelDecimalSeparator =","
      labelFileAdded = "Archivo Añadido"
      labelFileCountPlural ="Archivos"
      labelFileCountSingular ="Archivo"
      labelFileLoadError ="Error al subir"
      labelFileLoading ="Cargando"
      labelFileProcessing ="Subiendo"
      labelFileProcessingAborted ="Subida cancelada"
      labelFileProcessingComplete ="Subida completa"
      labelFileProcessingError ="Error al subir archivo"
      labelFileRemoved="Archivo eliminado"
      labelFileSizeNotAvailable ="Tamaño no disponible"
      labelFileWaitingForSize ="Comprobando tamaño"
      labelIdle ="Arrastre un archivo o pulse aqui"
      labelTapToRetry="Toca para reintentar"
      labelTapToUndo="Toca para deshacer"
      labelThousandsSeparator=""
    />
  </div>

</template>

<script>
import { ref } from 'vue'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview'
import FilePondPluginValidateSize from 'filepond-plugin-file-validate-size'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginPdfPreview,
  FilePondPluginValidateSize
)

export default {
  name: 'filePondUpload',
  components: {
    FilePond
  },
  setup (props, context) {
    const pond = ref({})

    const handleFilePondInit = () => {
      console.log('FilePond se ha iniciado correctamente')
    }

    const handleFilePondAddFile = (error, file) => {
      if (!error) {
        context.emit('captureFile', file.file)
      }
    }

    const handleFilePondInitFile = () => {
      console.log('Entre aqui init ....')
    }

    const handleFilePondRemoveFile = (error, file) => {
      if (!error) {
        context.emit('removeFile', file.file)
      }
    }

    const onRemoveSupport = async () => {
      console.log('Entre aqui...')
      return pond.value.removeFiles()
    }

    return {
      handleFilePondInit,
      handleFilePondInitFile,
      handleFilePondAddFile,
      handleFilePondRemoveFile,
      onRemoveSupport,
      pond
    }
  }

}
</script>

<style>

</style>
